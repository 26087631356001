<template>
  <header class="inx_header">
    <ul>
      <li class="fl">
        <p :class="['header_btn', matches ? 'btn_ac' : '']" @click="openMenu"></p>
        <router-link to="/home" class="logo">
          <img class="inx_logoImg" src="@/assets/uploads/au/au.png" />
        </router-link>
        <!-- 通知 -->
        <!-- <HeaderNotification :lang="lang"></HeaderNotification> -->
      </li>
      <li class="fr">
        <div class="user">
          <img src="@/assets/uploads/header/member_logo.png" alt class="member_logo" />{{ $t('header.welcome') }}
        </div>
        <el-dropdown trigger="click" @command="handleCommand" data-testid="dropdown">
          <span class="user_name_title" v-html="userName"></span>
          <i class="el-icon-arrow-down inx_down"></i>
          <el-dropdown-menu slot="dropdown" class="login_dropdown_box">
            <el-dropdown-item command="backToClientPortal" class="login_back" data-testid="goToCpPortal">
              <div class="line"></div>
              <span>{{ $t('header.bckToCP') }}</span>
            </el-dropdown-item>
            <el-dropdown-item command="logout" class="logout" data-testid="logout">
              <div class="line"></div>
              <span>{{ $t('header.logout') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="inx_lang_box">
          <el-dropdown trigger="click" @command="langCommand" data-testid="dropdownFlag">
            <div class="inx_lang">
              <img src="@/assets/uploads/header/lang.png" class="lang_logo" />
              <span v-html="language.label"></span>
              <i class="el-icon-arrow-down"></i>
            </div>
            <el-dropdown-menu slot="dropdown" class="lang_dropdown_box" placement="right-end">
              <p class="chooselang">{{ $t('header.chooselang') }}</p>
              <el-dropdown-item
                v-for="item in regulator == 'fca' ? fcaLanguages : languages"
                :key="item.value"
                :command="item"
                :class="{ active: lang == item.value }"
              >
                <img :src="item.icon" alt="" />
                <span>{{ item.label }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </li>
    </ul>
  </header>
</template>

<script>
import helper from '@/util/signinHelper';
import HeaderNotification from '@/components/notification/HeaderNotification';

export default {
  components: { HeaderNotification },
  name: 'vHeader',
  props: { matches: Boolean },
  data() {
    return {
      language: '',
      languages: [
        {
          value: 'en_US',
          label: 'English',
          icon: require('@/assets/uploads/flag/uk_flag.png')
        },
        {
          value: 'zh_CN',
          label: '中文',
          icon: require('@/assets/uploads/flag/cn_flag.png')
        },
        {
          value: 'zh_TW',
          label: '中文繁體',
          icon: require('@/assets/uploads/flag/hk_flag.png')
        },
        {
          value: 'fr_FR',
          label: 'Français',
          icon: require('@/assets/uploads/flag/fr_flag.png')
        },
        {
          value: 'ko',
          label: '한국어',
          icon: require('@/assets/uploads/flag/ko_flag.png')
        },
        {
          value: 'ja',
          label: '日本語',
          icon: require('@/assets/uploads/flag/ja_flag.png')
        },
        {
          value: 'th',
          label: 'ภาษาไทย',
          icon: require('@/assets/uploads/flag/th_flag.png')
        },
        {
          value: 'vi',
          label: 'Tiếng Việt',
          icon: require('@/assets/uploads/flag/vi_flag.png')
        },
        {
          value: 'es',
          label: 'Español',
          icon: require('@/assets/uploads/flag/es_flag.png')
        },
        {
          value: 'pt',
          label: 'Português',
          icon: require('@/assets/uploads/flag/pt_flag.png')
        },
        // {
        //   value: 'de',
        //   label: 'Deutsch',
        //   icon: require('@/assets/uploads/flag/de_flag.png')
        // },
        {
          value: 'id',
          label: 'Indonesian',
          icon: require('@/assets/uploads/flag/id_flag.png')
        },
        {
          value: 'ar',
          label: 'عربي',
          icon: require('@/assets/uploads/flag/ar_flag.png')
        },
        // {
        //   value: 'ru',
        //   label: 'Русский',
        //   icon: require('@/assets/uploads/flag/ru_flag.png')
        // },
        // {
        //   value: 'mn',
        //   label: 'Mонгол',
        //   icon: require('@/assets/uploads/flag/mn_flag.png')
        // },
        // {
        //   value: 'km',
        //   label: 'ខ្មែរ',
        //   icon: require('@/assets/uploads/flag/km_flag.png') //cambodia
        // },
        // {
        //   value: 'nl',
        //   label: 'Nederlands',
        //   icon: require('@/assets/uploads/flag/nl_flag.png')
        // },
        // {
        //   value: 'it',
        //   label: 'Italian',
        //   icon: require('@/assets/uploads/flag/it_flag.png')
        // },
        {
          value: 'ms',
          label: 'Malaysia',
          icon: require('@/assets/uploads/flag/my_flag.jpg')
        }
        // {
        //   value: 'pl',
        //   label: 'Poland',
        //   icon: require('@/assets/uploads/flag/pl_flag.png')
        // }
      ],
      fcaLanguages: [
        {
          value: 'en_US',
          label: 'English',
          icon: require('@/assets/uploads/flag/uk_flag.png')
        }
      ]
    };
  },
  watch: {
    language(lang) {
      this.lang = lang.value;
      document.body.dir = lang.value === 'ar' ? 'rtl' : 'ltr';
    },
    lang: {
      immediate: true,
      handler(val) {
        // 如果找不到預設第一筆
        this.language = this.languages.find(f => f.value == val) || this.languages[0];
      }
    }
  },
  computed: {
    userName() {
      return this.$store.state.common.userName;
    },
    lang: {
      get() {
        return this.$store.state.common.lang;
      },
      set(value) {
        return this.$store.commit('common/setLang', value);
      }
    }
  },
  methods: {
    openMenu() {
      this.$emit('openMenu');
    },
    handleCommand(command) {
      helper.signOut(command);
    },
    langCommand(command) {
      this.language = command;
      window.$zopim &&
        window.$zopim(function () {
          $zopim.livechat.setLanguage(command?.value || 'en');
        });
    }
  },
  mounted() {
    let callback = (val, oldVal, uri) => {
      console.log('localStorage change', val);
      if (val != this.userName) location.reload();
    };

    this.$ls.on('setUserName', callback);
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layout/header.scss';
</style>
