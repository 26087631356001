<template>
  <footer class="clearfix">
    <p>
      {{ $t('footer.riskWarn1', { value: $platform.getRiskValue(regulator) }) }}
    </p>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layout/footer.scss';
</style>
