<template>
  <div class="common_dialog_wrapper" ref="home_dialog">
    <el-dialog :title="notificationsItem.subject" :visible="visible" @close="close" :append-to-body="true">
      <div class="dialog_body">
        <button
          @click="handleSubChangePage()"
          class="circle_button arrow arrow_left"
          :class="{ ar_arrow: lang === 'ar' }"
        >
          <i class="el-icon-arrow-left"></i>
        </button>
        <button
          @click="handleAddChangePage()"
          class="circle_button arrow arrow_right"
          :class="{ ar_arrow: lang === 'ar' }"
        >
          <i class="el-icon-arrow-right"></i>
        </button>
        <div v-html="notificationsItem.document"></div>
      </div>
      <div slot="footer">
        <div class="d-flex align-items-center justify-content-between">
          <a
            @click="showLiveChat"
            class="d-flex align-items-center text-start"
            :class="{ ar_chat: lang === 'ar' }"
            data-testid="liveChat"
          >
            <img src="@/assets/uploads/chat.png" alt />
            <span class="ms-2" v-html="$t('common.liveChat.desc')"></span>
          </a>
          <el-pagination
            class="pb-0"
            :pager-count="5"
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="total"
            :page-size="1"
            :current-page="currentPage"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'notice',
  props: {
    notificationVisible: Boolean,
    notifications: Array
  },
  data() {
    return {
      visible: false,
      currentPage: 1,
      total: 1,
      notificationsItem: {}
    };
  },
  watch: {
    notificationVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:notificationVisible', bool);
    },
    currentPage() {
      this.assignCurrentPageData();
    }
  },
  methods: {
    close() {
      this.visible = false;
      this.currentPage = 1;
    },
    assignCurrentPageData() {
      this.notificationsItem = this.notifications[this.currentPage - 1];
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    handleAddChangePage() {
      this.currentPage < this.notifications.length && this.currentPage++;
    },
    handleSubChangePage() {
      this.currentPage > 1 && this.currentPage--;
    }
  },
  created() {
    this.total = this.notifications.length;
    this.assignCurrentPageData();
  },
  updated() {
    this.total = this.notifications.length;
    this.assignCurrentPageData();
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/notification/notice.scss';
</style>
