<template>
  <div class="notification_wrapper" v-show="loginStatus && showNotification">
    <div class="notice_mobile">
      <span class="notification" @click.stop.prevent="handleClickNotification" data-testid="notice_mobile">
        <em class="el-icon-warning"></em>
      </span>
    </div>
    <div class="noticeDisable">
      <span><em @click.stop.prevent="disableNotification" class="el-icon-close" data-testid="noticeDisable"></em></span>
    </div>
    <div class="notice" @click.stop.prevent="handleClickNotification" data-testid="notice">
      <span class="notification">{{ enabledNoticeTitle }}</span>
    </div>

    <template slot="components">
      <Notice :notificationVisible.sync="notificationVisible" :notifications="notifications"></Notice>
    </template>
  </div>
</template>

<script>
import { apiGetTranslation, apiGetNotification } from '@/resource';
import Notice from '@/components/notification/Notice';

export default {
  props: { lang: String },
  components: {
    Notice
  },
  watch: {
    loginStatus(loginStatus) {
      if (loginStatus) {
        this.getNotification(false);
      }
    },
    lang(lang) {
      this.getNotification(false);
    }
  },
  data() {
    return {
      notificationVisible: false,
      showNotification: true,
      enabledNotice: null,
      enabledNoticeTitle: null,
      typeDisplay: null,
      notifications: [
        {
          subject: '',
          status: 1,
          country: 'All',
          displayType: '',
          document: ''
        }
      ]
    };
  },
  mounted() {
    if (this.loginStatus) {
      this.getNotification(this.autoNotifyStatus);
    }
  },
  methods: {
    handleDecoding(str) {
      return str
        .replace(/amp;/gi, '')
        .replace(/&lt;/gi, '<')
        .replace(/&gt;/gi, '>')
        .replace(/&quot;/gi, '"')
        .replace(/&nbsp;/gi, ' ');
    },
    disableNotification() {
      this.showNotification = false;
    },
    handleClickNotification() {
      this.notificationVisible = true;
    },
    getTranslated() {
      let notices = this.notifications.reduce((acc, curr, idx) => {
        acc.push(curr.subject);
        acc[this.notifications.length + idx] = curr.document;
        return acc;
      }, []);

      // call google translate api
      apiGetTranslation({
        q: notices,
        source: 'en',
        target: this.$options.filters.googleLangSwitch(this.lang)
      })
        .then(res => {
          if (res.status === 200) {
            this.notifications.map((e, i) => {
              e.subject = res.data.data.translations[i].translatedText;
              e.document = res.data.data.translations[this.notifications.length + i].translatedText;
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getNotification(isShow) {
      apiGetNotification()
        .then(res => {
          if (res.data.data.length === 0) {
            this.disableNotification();
          } else {
            this.notifications = res.data.data
              .slice()
              .sort((a, b) => a.priority - b.priority)
              .map(e => {
                e.document = this.handleDecoding(e.document);
                return e;
              });
            this.enabledNoticeTitle = this.notifications.length ? this.notifications[0].subject : '';

            if (this.lang !== 'en_US') this.getTranslated();
            if (this.notifications[0].announcementDisplayType === 0 && isShow) {
              this.$store.commit('common/setAutoNotifyStatus', false);
              this.notificationVisible = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
          this.showNotification = false;
        });
    }
  },
  computed: {
    loginStatus() {
      return this.$store.state.common.loginStatus;
    },
    autoNotifyStatus() {
      return this.$store.state.common.autoNotifyStatus;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/notification/headerNotification.scss';
</style>
