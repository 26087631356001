<template>
  <div class="inner_max">
    <el-row :gutter="20" class="my-5">
      <el-col :xs="12" :sm="12" :md="6" v-for="(img, i) in images" :key="i"
        ><div class="grid-content ep-bg-purple"><img :src="getImageUrl(img)" /></div
      ></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'Alpine',
  data() {
    return {
      images: ['car1.png', 'partner1.png', 'car2.png', 'partner2.png']
    };
  },
  methods: {
    getImageUrl(image) {
      return require(`@/assets/uploads/footer/${image}`);
    }
  }
};
</script>

<style scoped>
.inner_max {
  padding: 0 3rem;
  margin: auto;
  max-width: 1200px;
}
</style>
