<template>
  <div id="menu">
    <div class="menu_shadow" v-show="matches && onOpen" @click="matchesClose"></div>
    <div class="menu_wrapper" :style="menu_wrapperStyle">
      <el-scrollbar style="height: 100%;">
        <el-collapse-transition>
          <el-menu class="el-menu-vertical-demo" :background-color="$platform.style.menuBackground"
            :text-color="$platform.style.menuTextColor" :active-text-color="$platform.style.menuActiveTextColor"
            :router="true" :default-active="$route.name" :collapse="isCollapse && !matches" data-testid="menu">
            <div v-for="(menu, index) in $platform.getMenus(regulator, $store.state.common.userID)" v-bind:key="index">
              <el-menu-item :index="menu.path" @click="checkMatchesClose" :data-testid="menu.path">
                <p :class="['icon', 'icon_' + menu.icon]" alt="" />
                <span slot="title" @click="menuClick(menu.path)" :data-testid="`click_${menu.path}`">
                  {{ $t(menu.name) }}
                </span>
              </el-menu-item>
            </div>
            <a v-if="!isCollapse || matches" class="swith_to" @click.stop.prevent="redirectToCp"
              data-testid="redirectToCp">{{ $t('menu.switchCP') }}</a>
          </el-menu>
        </el-collapse-transition>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import helper from '@/util/signinHelper';

export default {
  name: 'MenuTemplate',
  props: { isCollapse: Boolean, matches: Boolean, onOpen: Boolean },
  data() {
    return {
      bodyDirection: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.bodyDirection = document.body.dir;
    });
  },
  methods: {
    matchesClose() {
      this.$emit('matchesClose');
    },
    checkMatchesClose() {
      const vm = this;
      this.$nextTick(function () {
        if (vm.matches) vm.matchesClose();
      });
    },
    redirectToCp() {
      helper.signOut();
    },
    menuClick(url) {
      if (this.isCollapse) this.$router.push({ path: url });
    }
  },
  computed: {
    menu_wrapperStyle() {
      const menuWidth = { width: this.matches == false && this.isCollapse ? '61px' : '15rem' };
      const direction = this.bodyDirection === 'rtl' ? { right: this.menuPosition } : { left: this.menuPosition };
      return { ...direction, ...menuWidth };
    },
    menuPosition() {
      return this.matches && !this.onOpen ? '-15rem' : '0px';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layout/menu.scss';
</style>
